import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import styles from "./headerBack.module.scss"

function HeaderBack(props) {
  return (
    <>
      <Link to={props?.to} className={styles.header_close}>
        <div className={styles.btn_close}>
          <div>
            <span></span>
            <span></span>
          </div>
        </div>
      </Link>
    </>
  )
}
HeaderBack.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderBack.defaultProps = {
  siteTitle: ``,
}

export default HeaderBack
