import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"

import Client from "shopify-buy"
import Cookies from "js-cookie"
import {
    getCountryByAbbreviation,
    getCountry,
    formatLocaleCurrency,
} from "country-currency-map"

import styles from "./cart.module.scss"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"
import LocationPrice from "../../components/product/locationPrice/locationPrice"

import loading from "../../images/loading.gif"

// Initializing a client to return content in the store's primary language
const client = Client.buildClient({
    //domain: "jiwonchoi.myshopify.com/",
    domain: "shop.jiwchoi.com/",
    storefrontAccessToken: "1606be1610de6661c814f512b4bcdb71",
})


function Cart() {

    const checkoutId = Cookies.get('CHECKOUT_ID');
    const [checkout, setCheckout] = useState(null)
    const [totalPrice, setTotalPrice] = useState(null)
    const [locationCurrency, setLocationCurrency] = useState(null)

    const [checkoutLoading, setCheckoutLoading] = useState(false)

    // Get Checkout
    useEffect(() => {
        async function fetchCheckout() {
            client.checkout.fetch(checkoutId).then((checkout) => {
                console.log("checkout:", checkout);
                setCheckout(checkout)
            });
        }

        fetchCheckout()
    }, [])

    // Checkout to Shopify
    async function redirectCheckout() {
        setCheckoutLoading(true)

        // Track Facebook Pixel event
        if (window.fbq != null) {
            window.fbq('track', 'InitiateCheckout',
                {
                    currency: locationCurrency, //"EUR",
                    value: totalPrice
                });
            // debug
            // console.log('fired FBP event InitiateCheckout');
        }

        // alert(checkout.webUrl)
        window.location.href = checkout.webUrl
    }

    // Remove item from Cart
    async function removeItemCheckout(lineItemsId) {
        setCheckoutLoading(true)
        const lineItemIdsToRemove = [
            lineItemsId
        ];

        // Remove an item from the checkout
        await client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove).then((checkout) => {
            // Do something with the updated checkout
        });
        if (checkout?.lineItems.length == 1) {
            Cookies.remove('CHECKOUT_ID');
            window.location.reload();
        } else {
            window.location.reload();
        }
        setCheckoutLoading(false)
    }

    // fetch Currency
    async function fetchCurrency() {
        const ipInfoResponse = await fetch(
            "https://ipinfo.io/json?token=01fdab52f173f3"
        )
        const ipJson = await ipInfoResponse.json()

        const ipCountry = ipJson ? ipJson.country : "US"
        // Manual set
        //const ipCountry = "US"

        const country = getCountryByAbbreviation(ipCountry)
        const { currency } = getCountry(country)

        return currency
    }

    // Total Price
    async function countTotalPrice() {
        const currency = await fetchCurrency()

        const pricesFromLocation = checkout?.lineItems.map((lineItem, i) => {
            switch (currency) {
                case 'EUR':
                    return parseFloat(lineItem.variant.presentmentPrices[0].price.amount)
                    break;
                case 'GBP':
                    return parseFloat(lineItem.variant.presentmentPrices[1].price.amount)
                    break;
                case 'JPY':
                    return parseFloat(lineItem.variant.presentmentPrices[2].price.amount)
                    break;
                case 'USD':
                    return parseFloat(lineItem.variant.presentmentPrices[3].price.amount)
                    break;
                default:
                    return parseFloat(lineItem.variant.presentmentPrices[3].price.amount)
                    break;
            }
        })
        const totalPrice = pricesFromLocation?.reduce((a, b) => a + b, 0)
        // console.log(pricesFromLocation)
        setTotalPrice(totalPrice)
        setLocationCurrency(currency)
    }
    countTotalPrice()

    return (
        <Layout>
            <SEO title="Shop - Cart" />

            <div className={styles.headerCart}>
                <h4>Shopping Cart</h4>
            </div>

            {!checkoutId && (
                <>
                    <div style={{ minHeight: '90%' }}>
                        <div>
                            <div className={'flex center ta-c'}>
                                <h1>Your cart is empty, add product, enjoy shopping.</h1>
                            </div>
                            <div className={'flex center ta-c'}>
                                <Link to="/shop">Continue shopping</Link>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {(checkout?.lineItems.length >= 1) && (
                <div className={'flex center'} style={{ minHeight: '100%' }}>
                    <div className={'col col-7 col-l-9 col-m-10 col-s-11'}>
                        <div>
                            {checkout?.lineItems.map((lineItem, i) => (
                                <div key={i} className={styles.lineItem + ' flex middle'}>
                                    <div>
                                        <div className={styles.imagePreview + ' ' + 'bg bg-image'} style={{ backgroundImage: `url(${lineItem?.variant?.image.src})` }}></div>
                                    </div>
                                    <div>
                                        <h1>{lineItem?.title}</h1>
                                        <h2>Quantity: {lineItem?.quantity}</h2>
                                        <h2>Variant: {lineItem?.variant?.title}</h2>
                                        <h3>Price: <LocationPrice product={lineItem} /></h3>
                                    </div>
                                    <div className={styles.remove}>
                                        <div onClick={(e) => removeItemCheckout(lineItem?.id)}>
                                            <b>✖ REMOVE</b>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={'flex'}>
                            <div className={'col col-6 col-s-12'}>
                                <Link to={'/shop'} className={styles.buttonSecondary}>
                                    Continue Shopping
                                </Link>
                            </div>
                            <div className={'col col-6 col-s-12'}>
                                <div onClick={(e) => redirectCheckout()} className={styles.button}>
                                    {!checkoutLoading && (
                                        <b>BUY NOW / {totalPrice} {locationCurrency}</b>
                                    )}
                                    {checkoutLoading && (
                                        <img className={styles.loading} src={loading} />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={styles.info}>
                            <p>Payment process is powered by Shopify PCI compliant so you can keep payment secure and trust.</p>
                        </div>

                        {/* {console.log(checkout)} */}
                    </div>
                </div>
            )}
        </Layout>
    )

} export default Cart